<template>
  <transition name="fade" appear>
    <div class="home">
      <div class="center f20">{{ title }}</div>
      <div class="center f16">{{ time }}</div>
      <div class="content  ql-editor" style="white-space:pre-wrap;" v-html="content"></div>
      <!-- <div class="download">下载附件</div> -->
      <template v-for="(item, index) in List">
        <div :class="item - content" :key="index" class="left">
          <el-link icon="el-icon-paperclip" :class="item_content" @click="download(item)">{{ item.text }}</el-link>
        </div>
      </template>
    </div>
  </transition>
</template>

<script>
import { CHANGE_NAVINDEX } from "../../store/mutation-types";
export default {
  name: "holidayDetail",
  data() {
    return {
      title: "",
      time: "",
      url: "",
      content: "",
      activityCode: "",
      List: [],
    };
  },
  methods: {
    //查看详情
    download(item) {
      window.open(item.url);
    },
    getActivityDetail() {
      var getData = {};
      getData.activityCode = this.activityCode;
      this.$api.home.getActivityDetail1(getData).then((res) => {
        // console.log('list1',res.data)
        if (res.data.code === 200) {
          this.title = res.data.data.activityName;
          this.content = res.data.data.activityContent
        }
      });
    },
  },

  
  created() {
    this.$store.commit(CHANGE_NAVINDEX, "0");
    this.activityCode = this.$route.query.activityCode;
    this.getActivityDetail();
    // console.log('list2',this.list)
  
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 1140px;
  padding: 0 140px;
  margin-top: 45px;
  .center {
    text-align: center;
  }
  .f20 {
    font-size: 26px;
    z-index: 0;
    text-align: center;
  }
  .f16 {
    font-size: 20px;
    margin-top: 25px;
  }
  .content {
    margin: 21px 0;
    font-size: 17px;
    word-break: break-all;
    // text-align: left;
    overflow-x: hidden;
  }
  .content{
   /deep/ img {
        // width: 90%;
        // height: auto;
        margin-left: 8%;
    }
}
  .download {
    font-size: 18px;
    color: #fab300;
    margin-bottom: 15px;
    text-align: left;
    img {
      width: 23px;
      margin-right: 5px;
      height: 1.5rem;
    }
  }
   .download:hover{
     cursor: pointer;
   }
  .left {
    text-align: left;
    margin-top: 5px;
  }
  .el-link {
    font-size: 20px;
    text-align: left;
  }
}
</style>
